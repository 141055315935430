import {API_REQUEST} from "@/utils/actionConstants";
import store from "@/store";
import {getSearchParams} from "@/utils/actions";

export function saveProduct(item) {
    const request = {
        type: API_REQUEST,
        url: item.id && item.id > 0 ? `/products/${item.id}/` : '/products/',
        method: item.id && item.id > 0 ? 'patch' : 'post',
        payload: {name: item.name, item_number: item.item_number, ideabook: item.ideabook},
    }
    return store.dispatch(API_REQUEST, request);
}

export function saveMakerProduct(itemToUpdate) {
    const request = {
        type: API_REQUEST,
        url: itemToUpdate.id > 0 ? `/products/maker/${itemToUpdate.id}/` : '/products/maker/',
        method: itemToUpdate.id > 0 ? 'patch' : 'post',
        payload: itemToUpdate,
        params: null,
    }
    return store.dispatch(API_REQUEST, request);
}

export function getMakerProductByItem(itemNumber) {
    const request = {
        type: API_REQUEST,
        url: `/products/maker/product/${itemNumber}`,
        method: 'get',
        params: null,
        noAlert: true
    }
    return store.dispatch(API_REQUEST, request);
}

export function searchMakerProductList(search = null, page = null) {
    let params = getSearchParams(search, page)
    const request = {
        type: API_REQUEST,
        url: '/products/maker/',
        method: 'get',
        payload: null,
        params: params,
    }
    return store.dispatch(API_REQUEST, request);
}

export function deleteMakerProduct(item) {
    const request = {
        type: API_REQUEST,
        url: `/products/maker/${item.id}/`,
        method: 'delete',
        id: item.id,
    }
    return store.dispatch(API_REQUEST, request);
}

export function deleteMakerProductImage(id) {
    const request = {
        type: API_REQUEST,
        url: `/products/maker/${id}/image/`,
        id: id,
        method: 'delete',
    }
    return store.dispatch(API_REQUEST, request);
}

export function uploadMakerProductImage(id, formData) {
    const request = {
        type: API_REQUEST,
        url: `/products/maker/${id}/image/`,
        method: 'post',
        payload: formData,
        headers: {'Content-Type': undefined}
    }
    return store.dispatch(API_REQUEST, request);
}

export function importMakerProducts(formData, type) {
    const request = {
        type: API_REQUEST,
        url: `/importer/${type}/`,
        method: 'post',
        payload: formData,
        headers: {'Content-Type': undefined},
        noAlert: true
    }
    return store.dispatch(API_REQUEST, request);
}

export function fetchIdeaBooks() {
    const request = {
        type: API_REQUEST,
        url: '/idea-books',
        method: 'get'
    }
    return store.dispatch(API_REQUEST, request);
}

export function deleteProduct(product_id, force=false) {
    const request = {
        type: API_REQUEST,
        url: `/products/${product_id}/`,
        id: product_id,
        params: {force},
        method: 'delete',
        noAlert: true,
    }
    return store.dispatch(API_REQUEST, request);
}

export function deleteImage(product_id) {
    const request = {
        type: API_REQUEST,
        url: `/products/${product_id}/image/`,
        id: product_id,
        method: 'delete',
    }
    return store.dispatch(API_REQUEST, request);
}

export function uploadProductImage(product_id, formData) {
    const request = {
        type: API_REQUEST,
        url: `/products/${product_id}/image/`,
        method: 'post',
        payload: formData,
        headers: {'Content-Type': undefined}
    }
    return store.dispatch(API_REQUEST, request);
}

export function uploadProductCsv(formData) {
    const request = {
        type: API_REQUEST,
        url: '/products/upload/',
        method: 'post',
        payload: formData,
        noAlert: true,
        headers: { 'Content-Type': 'multipart/form-data' }
    }
    return store.dispatch(API_REQUEST, request);
}

export function productSearch(search = null, page = null) {
    let params = getSearchParams(search, page)
    const request = {
        type: API_REQUEST,
        url: '/products/',
        method: 'get',
        payload: null,
        params: params,
        onSuccess: null,    // Results are not for the global store, must be handled by client
    }
    return store.dispatch(API_REQUEST, request);
}

export function productSearchForAdd(search) {
    let params = getSearchParams(search);
    const request = {
        type: API_REQUEST,
        url: '/products/available/',
        method: 'get',
        payload: null,
        params: {...params, for_maker: true},
        onSuccess: null,    // Results are not for the global store, must be handled by client
    }
    return store.dispatch(API_REQUEST, request);
}
